<template>
  <div>
    <a-drawer
      class="popover-main"
      :title="title"
      placement="right"
      width="800"
      :closable="true"
      :maskClosable="false"
      :visible="visible"
      @close="onClose"
    >
      <div class="loading" v-if="loadingBol">
        <a-spin size="large" />
      </div>
      <div v-if="dataInfo !== null">
        <a-tabs
          class="tabs-main"
          :default-active-key="studentInfo[0].id"
          tab-position="left"
          @prevClick="callback"
          @nextClick="callback"
          @tabClick="handleTab"
          v-if="studentInfo.length !== 1"
        >
          <a-tab-pane v-for="i in studentInfo" :key="i.id">
            <span slot="tab" style="width：150px" :class="handleName(i)">
              {{ i.name }}
            </span>
            <h2>{{ dataInfo.fullName }}</h2>
            <p><a-icon type="clock-circle" style="marginright: 10px" />{{ getTimezoneName(dataInfo.timezone) }}</p>
            <h3>基本信息</h3>
            <div class="info basic-info">
              <div>
                <label>学生名</label> <span>{{ dataInfo.fullName }}</span>
              </div>
              <div>
                <label>英文名</label> <span>{{ dataInfo.data.EnglishName || '无' }}</span>
              </div>
              <div>
                <label>性别</label> <span>{{ dataInfo.data.Gender || '无' }}</span>
              </div>
              <div>
                <label>年龄</label> <span>{{ dataInfo.countAge || '无' }}</span>
              </div>
              <div>
                <label>常驻城市</label> <span>{{ dataInfo.data.city || '无' }}</span>
              </div>
              <div>
                <label>所在时区</label> <span>{{ getTimezoneName(dataInfo.timezone) || '无' }}</span>
              </div>
              <div>
                <label>中文班主任</label>
                <span>{{
                  dataInfo.classAdmin &&
                  dataInfo.classAdmin.CHINESE &&
                  dataInfo.classAdmin.CHINESE.sfId !== '0052x000003QUfnAAG'
                    ? dataInfo.classAdmin.CHINESE.fullName
                    : '无'
                }}</span>
              </div>
              <div>
                <label>数学辅导老师</label>
                <span>{{
                  dataInfo.classAdmin &&
                  dataInfo.classAdmin.MATH &&
                  dataInfo.classAdmin.MATH.sfId !== '0052x000003QUfnAAG'
                    ? dataInfo.classAdmin.MATH.fullName
                    : '无'
                }}</span>
              </div>
              <div>
                <label style="width: 120px">中文学习规划师</label>
                <span>{{
                  dataInfo.data &&
                  dataInfo.data.courseConsultant &&
                  dataInfo.data.courseConsultant.CHINESE &&
                  dataInfo.data.courseConsultant.CHINESE.sfId !== '0052x000003QUfnAAG'
                    ? dataInfo.data.courseConsultant.CHINESE.name
                    : '无'
                }}</span>
              </div>
              <div>
                <label style="width: 120px">数学学习规划师</label>
                <span>{{
                  dataInfo.data &&
                  dataInfo.data.courseConsultant &&
                  dataInfo.data.courseConsultant.MATH &&
                  dataInfo.data.courseConsultant.MATH.sfId !== '0052x000003QUfnAAG'
                    ? dataInfo.data.courseConsultant.MATH.name
                    : '无'
                }}</span>
              </div>
            </div>
            <h3 v-if="studentHistory && studentHistory.length !== 0">{{ historyName }}历史记录</h3>
            <template v-if="studentHistory">
              <div class="history-info" v-for="(item, index) in studentHistory" :key="index">
                <span class="info-title">{{ item.courseScheduleRespDTO.scheduleName }}</span>
                <span>{{ moment(new Date(item.courseScheduleRespDTO.startDateTime)).format('YY/MM/DD') }}</span>
                <span>
                  {{ moment(new Date(item.courseScheduleRespDTO.startDateTime)).format('dddd') }}
                  {{ item.courseScheduleRespDTO.startDateTime.substring(11) }}-
                  {{ item.courseScheduleRespDTO.endDateTime.substring(11) }}
                </span>
                <router-link
                  :to="{ path: '/courseDetails', query: { uuid: item.courseScheduleRespDTO.uuid } }"
                  class="toInfo"
                  :style="{ color: item.courseScheduleRespDTO.uuid === uuid ? '#999' : '' }"
                >
                  查看详情</router-link
                >
              </div>
            </template>
            <h3>体验课</h3>
            <div class="admission-Info">
              <div>
                <label>回放链接</label>
                <a
                  v-if="dataInfo.data.entryCriteria.trialClassVideoUrl"
                  :href="tourl(dataInfo.data.entryCriteria.trialClassVideoUrl)"
                  target="_blank"
                  >{{ dataInfo.data.entryCriteria.trialClassVideoUrl || '无' }}</a
                >
                <span v-else>{{ dataInfo.data.entryCriteria.trialClassVideoUrl || '无' }}</span>
              </div>
            </div>
            <h3>入学学情</h3>
            <div class="info">
              <div>
                <label>拼音学习史</label><span>{{ dataInfo.data.entryCriteria.pinyinHistory || '无' }}</span>
              </div>
              <div>
                <label>孩子中文水平</label><span>{{ dataInfo.data.entryCriteria.chineseLevel || '无' }}</span>
              </div>
              <div>
                <label>家庭环境语</label> <span>{{ dataInfo.data.entryCriteria.familyLanguage || '无' }}</span>
              </div>
              <div>
                <label>汉字书写预期</label><span>{{ dataInfo.data.entryCriteria.writingExpectation || '无' }}</span>
              </div>
              <div>
                <label>国内中文学习史 </label> <span>{{ dataInfo.data.entryCriteria.chinaStudyHistory || '无' }}</span>
              </div>
              <div>
                <label>曾用教材</label> <span>{{ dataInfo.data.entryCriteria.textbookUsed || '无' }}</span>
              </div>
              <div>
                <label>海外中文学习史 </label>
                <span>{{ dataInfo.data.entryCriteria.overseasStudyHistory || '无' }}</span>
              </div>
              <div>
                <label>曾用教材学到第几册 </label>
                <span>{{ dataInfo.data.entryCriteria.textbookProgress || '无' }}</span>
              </div>
            </div>
            <div class="admission-Info">
              <div>
                <label>家长教学期望</label><span>{{ dataInfo.data.entryCriteria.teachingExpectation || '无' }}</span>
              </div>
              <div>
                <label>授课风格偏好</label> <span>{{ dataInfo.data.entryCriteria.teachingStyle || '无' }}</span>
              </div>
              <div>
                <label>家长画像与注意事项</label> <span>{{ dataInfo.data.entryCriteria.parentProfile || '无' }}</span>
              </div>
              <div>
                <label>学生画像和爱好、兴趣点</label>
                <span>{{ dataInfo.data.entryCriteria.studentProfile || '无' }}</span>
              </div>
              <div>
                <label>中文程度-补充说明</label>
                <span>{{ dataInfo.data.entryCriteria.chineseAdditionalRemark || '无' }}</span>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
        <div v-else class="one-student-info">
          <h2>{{ dataInfo.fullName }}</h2>
          <p><a-icon type="clock-circle" style="marginright: 10px" />{{ getTimezoneName(dataInfo.timezone) }}</p>
          <h3>基本信息</h3>
          <div class="info basic-info">
            <div>
              <label>学生名</label> <span>{{ dataInfo.fullName }}</span>
            </div>
            <div>
              <label>英文名</label> <span>{{ dataInfo.data.EnglishName || '无' }}</span>
            </div>
            <div>
              <label>性别</label> <span>{{ dataInfo.data.Gender || '无' }}</span>
            </div>
            <div>
              <label>年龄</label> <span>{{ dataInfo.countAge || '无' }}</span>
            </div>
            <div>
              <label>常驻城市</label> <span>{{ dataInfo.data.city || '无' }}</span>
            </div>
            <div>
              <label>所在时区</label> <span>{{ getTimezoneName(dataInfo.timezone) || '无' }}</span>
            </div>
            <div>
              <label>中文班主任</label>
              <span>{{
                dataInfo.classAdmin &&
                dataInfo.classAdmin.CHINESE &&
                dataInfo.classAdmin.CHINESE.sfId !== '0052x000003QUfnAAG'
                  ? dataInfo.classAdmin.CHINESE.fullName
                  : '无'
              }}</span>
            </div>
            <div>
              <label>数学辅导老师</label>
              <span>{{
                dataInfo.classAdmin &&
                dataInfo.classAdmin.MATH &&
                dataInfo.classAdmin.MATH.sfId !== '0052x000003QUfnAAG'
                  ? dataInfo.classAdmin.MATH.fullName
                  : '无'
              }}</span>
            </div>
            <div>
              <label style="width: 120px">中文学习规划师</label>
              <span>{{
                dataInfo.data &&
                dataInfo.data.courseConsultant &&
                dataInfo.data.courseConsultant.CHINESE &&
                dataInfo.data.courseConsultant.CHINESE.sfId !== '0052x000003QUfnAAG'
                  ? dataInfo.data.courseConsultant.CHINESE.name
                  : '无'
              }}</span>
            </div>
            <div>
              <label style="width: 120px">数学学习规划师</label>
              <span>{{
                dataInfo.data &&
                dataInfo.data.courseConsultant &&
                dataInfo.data.courseConsultant.MATH &&
                dataInfo.data.courseConsultant.MATH.sfId !== '0052x000003QUfnAAG'
                  ? dataInfo.data.courseConsultant.MATH.name
                  : '无'
              }}</span>
            </div>
          </div>
          <h3 v-if="studentHistory && studentHistory.length !== 0">{{ historyName }}历史记录</h3>
          <template v-if="studentHistory">
            <div class="history-info" v-for="(item, index) in studentHistory" :key="index">
              <span class="info-title">{{ item.courseScheduleRespDTO.scheduleName }}</span>
              <span>{{ moment(new Date(item.courseScheduleRespDTO.startDateTime)).format('YY/MM/DD') }}</span>
              <span>
                {{ moment(new Date(item.courseScheduleRespDTO.startDateTime)).format('dddd') }}
                {{ item.courseScheduleRespDTO.startDateTime.substring(11) }}-
                {{ item.courseScheduleRespDTO.endDateTime.substring(11) }}
                {{ teacherTime }}
              </span>
              <router-link
                :to="{ path: '/courseDetails', query: { uuid: item.courseScheduleRespDTO.uuid } }"
                class="toInfo"
                :style="{ color: item.courseScheduleRespDTO.uuid === uuid ? '#999' : '' }"
              >
                查看详情</router-link
              >
            </div>
          </template>
          <h3>体验课</h3>
          <div class="admission-Info">
            <div>
              <label>回放链接</label>
              <a
                v-if="dataInfo.data.entryCriteria.trialClassVideoUrl"
                :href="tourl(dataInfo.data.entryCriteria.trialClassVideoUrl)"
                target="_blank"
                >{{ dataInfo.data.entryCriteria.trialClassVideoUrl || '无' }}</a
              >
              <span v-else>{{ dataInfo.data.entryCriteria.trialClassVideoUrl || '无' }}</span>
            </div>
          </div>
          <h3>入学学情</h3>
          <div class="info">
            <div>
              <label>拼音学习史</label><span>{{ dataInfo.data.entryCriteria.pinyinHistory || '无' }}</span>
            </div>
            <div>
              <label>孩子中文水平</label><span>{{ dataInfo.data.entryCriteria.chineseLevel || '无' }}</span>
            </div>
            <div>
              <label>家庭环境语</label> <span>{{ dataInfo.data.entryCriteria.familyLanguage || '无' }}</span>
            </div>
            <div>
              <label>汉字书写预期</label><span>{{ dataInfo.data.entryCriteria.writingExpectation || '无' }}</span>
            </div>
            <div>
              <label>国内中文学习史 </label> <span>{{ dataInfo.data.entryCriteria.chinaStudyHistory || '无' }}</span>
            </div>
            <div>
              <label>曾用教材</label> <span>{{ dataInfo.data.entryCriteria.textbookUsed || '无' }}</span>
            </div>
            <div>
              <label>海外中文学习史 </label> <span>{{ dataInfo.data.entryCriteria.overseasStudyHistory || '无' }}</span>
            </div>
            <div>
              <label>曾用教材学到第几册 </label> <span>{{ dataInfo.data.entryCriteria.textbookProgress || '无' }}</span>
            </div>
          </div>
          <div class="admission-Info">
            <div>
              <label>家长教学期望</label><span>{{ dataInfo.data.entryCriteria.teachingExpectation || '无' }}</span>
            </div>
            <div>
              <label>授课风格偏好</label> <span>{{ dataInfo.data.entryCriteria.teachingStyle || '无' }}</span>
            </div>
            <div>
              <label>家长画像与注意事项</label> <span>{{ dataInfo.data.entryCriteria.parentProfile || '无' }}</span>
            </div>
            <div>
              <label>学生画像和爱好、兴趣点</label>
              <span>{{ dataInfo.data.entryCriteria.studentProfile || '无' }}</span>
            </div>
            <div>
              <label>中文程度-补充说明</label>
              <span>{{ dataInfo.data.entryCriteria.chineseAdditionalRemark || '无' }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import moment from 'moment';

import { getTimezoneName } from '@/utils/geoLocation';

export default {
  props: {
    studentInfo: {
      type: Array,
      default: () => [],
    },
    courseName: {
      type: String,
      default: '',
    },
    studentLiveUrl: {
      type: String,
      default: '',
    },
    courseId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      getTimezoneName,
      title: `学生详情${this.studentInfo.length !== 1 ? `(${this.studentInfo.length})` : ''}`,
      loadingBol: false,
      visible: true,
      dataInfo: null,
      studentHistory: null,
      historyName: this.courseName,

      uuid: this.$route.query.uuid,
    };
  },
  created() {
    this.handleTab(this.studentInfo[0].id);
  },
  methods: {
    moment,
    tourl(url = '') {
      return url.indexOf('http') === -1 ? `https://${url}` : url;
    },
    handlegetContainer(trigger) {
      return trigger.parentElement;
    },
    handleName(data) {
      let status = '';
      switch (data.status) {
        case 'NORMAL':
          status = ''; // 代开课
          break;
        case 'COMPLETED':
          status = ''; // 已完成
          break;
        case 'ABSENT':
          status = 'ABSENT'; // 旷课
          break;
        default:
          break;
      }
      return status;
    },
    handleTab(val = '') {
      this.studentHistory = null;
      if (!val) return;
      this.loadingBol = true;
      this.$fetch(`/api/service_center/course_schedule/student/${val}`).then((res) => {
        if (res.status === 200) {
          this.loadingBol = false;
          this.dataInfo = res.data;
          if (this.dataInfo.data.gender === 'male') {
            this.dataInfo.data.Gender = '男';
          } else if (this.dataInfo.data.gender === 'female') {
            this.dataInfo.data.Gender = '女';
          } else {
            this.dataInfo.data.Gender = '无';
          }
          this.dataInfo.data.entryCriteria = this.dataInfo.data.entryCriteria ? this.dataInfo.data.entryCriteria : {};
          if (res.data.dateOfBirth) {
            const age = Math.round(
              (new Date().getTime() - new Date(res.data.dateOfBirth).getTime()) / (365 * 24 * 60 * 60 * 1000),
            );
            this.dataInfo.countAge = age;
          }
          this.studentInfo.forEach((item) => {
            if (item.id === val && item.identity === 'TRANSFER') {
              this.getHistory(val);
            }
          });
        }
      });
    },
    getHistory(val) {
      this.$post(`/api/homework/practice/schedule/search/1`, {
        studentId: val,
        pageSize: 3,
        courseId: this.courseId,
      }).then((res) => {
        if (res.status === 200) {
          this.studentHistory = res.data.content;
        }
      });
    },
    callback() {},
    onClose() {
      this.loadingBol = false;
      this.$parent.cancal();
    },
  },
};
</script>
<style lang="less">
.popover-main {
  z-index: 1040;
  // .ant-drawer-mask{
  //   z-index: 10;
  // }
  h2 {
    padding-top: 12px;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-tabs-nav {
    .ant-tabs-tab-active {
      color: #000;
      background-color: #fff;
    }
    .ant-tabs-tab {
      width: 150px;
      text-align: center;
      padding: 16px 24px;
      text-align: left;
      margin: 0;
    }
    .ant-tabs-ink-bar {
      background-color: #fff;
    }
  }
  .ant-tabs-nav-wrap {
    background-color: #f7f7f7;
  }
  .ant-tabs-left-content {
    border-left: none;
  }
  h3 {
    margin: 10px 0;
  }
  .tabs-main {
    height: calc(100vh - 48px);
    overflow-y: auto;
  }
  .ABSENT::after {
    content: '旷课';
    display: inline-block;
    width: 30px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    text-align: center;
    color: #ff5353;
    background-color: #fff;
    border-radius: 8px;
    vertical-align: bottom;
  }
  .one-student-info {
    padding: 0 30px;
  }
  .ant-tabs-content {
    overflow-y: auto;
    height: calc(100vh - 48px);
  }
  .info,
  .admission-Info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    color: #000;
    div {
      display: flex;
      justify-self: start;
      align-items: center;
      width: 50%;
      margin: 10px 0;
    }
    label {
      display: inline-block;
      width: 140px;
      text-align: right;
      color: #999;
      margin-right: 20px;
    }
    span {
      display: inline-block;
      width: 60%;
    }
  }
  .basic-info {
    label {
      width: 120px;
      margin-left: 20px;
    }
    span {
      display: inline-block;
      width: 50%;
    }
  }
  .admission-Info {
    label {
      width: 120px;
      margin-left: 22px;
    }
    div {
      width: 100%;
    }
    a,
    span {
      display: inline-block;
      width: 80%;
    }
  }
  .history-info {
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: space-between;
    .info-title {
      display: inline-block;
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .toinfo {
      color: #009cff;
    }
  }
  .loading {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 50% 0 0 0;
    text-align: center;
    line-height: 100%;
    font-size: 60px;
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 9999;
  }
}
</style>
