<template>
  <div class="demo-app">
    <div
      class="demo-app-main"
      style="position: relative"
      ref="main"
      :class="{ calendarTools: viewType === 'timeGridWeek' }"
    >
      <FullCalendar ref="FullCalendar" class="demo-app-calendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <a-popover
            placement="topLeft"
            trigger="click"
            :get-popup-container="getPopupContainer"
            overlayClassName="coursePopover"
            :visible="popoverids === arg.event.id"
            v-if="viewType !== 'dayGridMonth'"
          >
            <template slot="content">
              <a-icon type="close-circle" class="closed" @click="getvisibleChange1" />
              <div v-if="courseSchedule !== null" style="overflow: hidden">
                <p class="course-title" @click="toCourseDetails(courseSchedule.uuid)">
                  {{ courseSchedule.scheduleName
                  }}<span>{{ courseSchedule.classType === 'PRIVATE' ? '私教课' : '班课' }}</span>
                </p>
                <p class="course-info"><span>上课时间：</span>{{ courseSchedule.timeValue }}</p>
                <p class="course-info">
                  <span>休息时长：</span>课后{{ courseSchedule.courseSectionRespDTO.timeOut }}分钟
                </p>
                <p class="course-info"><span>所属班级：</span>{{ courseSchedule.formClassName }}</p>
                <p class="course-info students-info" @click="toStudentInfo">
                  <span>学生：</span
                  >{{
                    courseSchedule.students &&
                    (courseSchedule.students.length > 1 || courseSchedule.students.length === 0)
                      ? courseSchedule.students.length + '人>'
                      : courseSchedule.students[0].name + '>'
                  }}
                </p>
                <p
                  class="course-info"
                  v-if="
                    changecourse &&
                    (arg.event.extendedProps.isAdjustSection === null || arg.event.extendedProps.isAdjustSection)
                  "
                >
                  <span>课节进度：</span>{{ courseSchedule.courseSectionRespDTO.sectionName }}
                  <span style="color: #009cff" v-if="arg.event.extendedProps.isAdjustSection" @click="changecourseFuc"
                    >修改</span
                  >
                </p>
                <div class="course-form" v-else-if="!changecourse || !arg.event.extendedProps.isAdjustSection">
                  <p class="course-info">
                    <span>课节进度：</span>
                    <a-select
                      show-search
                      style="width: 240px"
                      :default-value="courseSchedule.courseSectionRespDTO.uuid"
                      :filterOption="filterOption"
                      @change="handleSelectChange"
                      @search="onSeachsectionName"
                      @popupScroll="handlePopupScroll"
                      v-if="selectArr !== null || selectArr.length !== 0"
                    >
                      <a-select-option v-for="(item, index) in selectArr" :key="index" :value="item.uuid">{{
                        item.sectionName
                      }}</a-select-option>
                    </a-select>
                  </p>
                  <p><a-checkbox :checked="true"></a-checkbox>自动更新之后章节</p>
                  <p class="tips">* 请确认课节进度是否正确，并严格按照该进度上课</p>
                  <div>
                    <a-button @click="getvisibleChange1">取消</a-button>
                    <a-button type="primary" @click="submitTips" :loading="courseBtn" :disabled="courseBtn"
                      >确认</a-button
                    >
                  </div>
                </div>
                <img
                  v-if="arg.event.extendedProps.isAdjustSection === null && courseSchedule.scheduleStatus !== 'NORMAL'"
                  src="@/assets/icons/service_pic_finished.svg"
                  alt=""
                />
              </div>
              <div style="textalign: center" v-else>
                <a-spin size="large" />
              </div>
            </template>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span v-if="!arg.event.classNames.includes('hotcls')"> {{ arg.event.title }}</span>
                <!-- {{ arg.event.title }}{{ arg.event.extendedProps.status }}</span
                > -->
              </template>
              <span
                v-if="viewType !== 'dayGridMonth'"
                style="
                  display: block;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  padding: 2px 10px;
                  height: 100%;
                "
                :class="{ eventCls: popoverids === arg.event.id }"
              >
                <a-icon
                  v-if="arg.event.id === 'null' && !arg.event.classNames.includes('lightRed')"
                  style="font-size: 14px; margin-right: 10px"
                  type="check-circle"
                  theme="filled"
                />
                <a-icon
                  type="close-circle"
                  v-if="arg.event.id === 'null' && arg.event.classNames.includes('lightRed')"
                  theme="filled"
                  style="font-size: 14px; margin-right: 10px"
                />
                {{ arg.event.title }}
              </span>
            </a-tooltip>
          </a-popover>
          <div v-else :style="{ width: '100%' }">
            <a-tooltip placement="topLeft" :visible="popoverids === arg.event.id">
              <template slot="title">
                <span>{{ arg.event.title }} </span>
              </template>
              <div :style="{ backgroundColor: arg.event.backgroundColor, color: '#fff' }">{{ arg.event.title }}</div>
            </a-tooltip>
          </div>
        </template>
      </FullCalendar>
      <div class="calendar-header">
        <span style="font-size: 18px; margin-right: 10px">{{ teacherName }}</span>
        <a-select
          style="width: 300px"
          show-search
          placeholder="所在时区"
          option-filter-prop="children"
          :filter-option="filterOption"
          v-model="timezone"
          @change="changeTimezone"
        >
          <a-select-option :value="i.value" v-for="(i, index) of timezoneList" :key="index">
            {{ i.label }}
          </a-select-option>
        </a-select>
        <div class="tag-wrap">
          <span class="tag-pd"><a-tag color="orange" class="tag-item"></a-tag>体验课可用</span>
          <span class="tag-pd"><a-tag color="green" class="tag-item"></a-tag>标准课可用</span>
          <span class="tag-pd"><a-tag color="blue" class="tag-item"></a-tag>通用时间</span>
          <span class="tag-pd"><a-tag color="#009cff" class="tag-item"></a-tag>已排课</span>
          <span class="tag-pd"><a-tag color="rgb(250 125 112)" class="tag-item"></a-tag>不可用</span>
        </div>
      </div>
      <div class="calendarLoading" v-if="showLoading">
        <a-spin size="large" />
      </div>
      <div class="statistics-time" v-if="viewType === 'timeGridWeek' && availableTime !== null">
        <div class="leave-related">
          <p>
            该周开放时间:
            <a-popover
              placement="bottomLeft"
              :get-popup-container="getPopupContainer1"
              :visible="showAllTime"
              overlayClassName="courseTime"
            >
              <template v-if="workStatus.workStatus === 'WORKING_AVAILABLE'">
                <template slot="content" v-if="workStatus.workType === 'F' && Number(availableTime.availableTime) < 50">
                  <span v-if="workStatus.jobType === '教学职能岗' && Number(availableTime.availableTime) < 20">
                    <a-icon
                      type="exclamation-circle"
                    />您的本周开放时间＜20h，请尽快前往[可用时间设置]添加，如排课时间已达20h，可不再增加
                  </span>
                  <span v-else>
                    <a-icon
                      type="exclamation-circle"
                    />您的本周开放时间＜50h，请尽快前往[可用时间设置]添加，如排课时间已达30h，可不再增加
                  </span>
                </template>
                <template slot="content" v-if="workStatus.workType === 'P' && Number(availableTime.availableTime) < 20">
                  <span v-if="workStatus.jobType === '教学职能岗' && Number(availableTime.availableTime) < 10">
                    <a-icon
                      type="exclamation-circle"
                    />您的本周开放时间＜10h，请尽快前往[可用时间设置]添加，如排课时间已达10h，可不再增加
                  </span>
                  <span v-else>
                    <a-icon
                      type="exclamation-circle"
                    />您的本周开放时间＜20h，请尽快前往[可用时间设置]添加，如排课时间已达20h，可不再增加
                  </span>
                </template>
              </template>
              <span class="allTime">{{ availableTime.availableTime }}</span>
              <!-- <span class="allTime">{{ availableTime.workTime }}</span> -->
            </a-popover>
            小时
            <a-tooltip overlayClassName="tooltips">
              <template slot="title">
                <!-- <span>「总工作时长」为可用时间与已排课时间总和（去重），再减去重合的不可用时间</span> -->
                <span>【计算逻辑】本周开放时间（即绿色方框时间）</span>
              </template>
              <a-icon type="exclamation-circle" />
            </a-tooltip>
          </p>
          <!-- <a-button class="leave" @click="onAddNoTime">添加不可用时间（春节）</a-button> -->
        </div>
        <div class="schedule-color">
          <p>
            <!-- <i class="schedule-blue"></i> -->
            已排课:<span class="schedule-blue">{{ availableTime.courseScheduleTime }}</span
            >小时
          </p>
          <p>
            <!-- <i class="schedule-green"></i> -->
            可用时间:<span class="schedule-green">{{ availableTime.availableTime }}</span
            >小时
          </p>
          <p>
            <!-- <i class="schedule-gray"></i> -->
            不可用时间:<span class="schedule-gray">{{ availableTime.unavailableTime }}</span
            >小时
          </p>
        </div>
      </div>
    </div>
    <component
      :is="currentView"
      :studentInfo="studentInfo"
      :courseName="courseSchedule ? courseSchedule.courseSectionRespDTO.course.courseName : ''"
      :scheduleSubject="courseSchedule ? courseSchedule.courseSectionRespDTO.course.subject : ''"
      :courseId="courseSchedule ? courseSchedule.courseSectionRespDTO.course.uuid : ''"
      @cancal="cancal"
      @changeCourse="changeCourse"
    ></component>

    <a-modal
      wrapClassName="feedBacktips"
      width="350px"
      :visible="tipsVisible"
      :footer="null"
      :mask="false"
      @cancel="onCancelTips"
    >
      <p><a-icon type="check-circle" /> 反馈已提交</p>
      <p>
        您可随时前往
        <router-link :to="{ name: 'upcoming', query: { type: 'Icreate' } }">我的任务>我创建</router-link>查看进度
      </p>
    </a-modal>
    <!-- <a-modal
      :visible="resignVisible"
      :footer="null"
      dialogClass="submit-tips"
      width="560px"
      @cancel="onCanceltips"
      :maskClosable="false"
      wrapClassName="tips-related"
      :maskStyle="{ zIndex: 1050 }"
    >
      <div class="tips-main">
        <p class="tips-title">确认修改课节</p>
        <p class="tips-text">请确认课节进度是否正确，并严格按照该进度上课</p>
      </div>
      <div class="btn">
        <a-button @click="onCanceltips"> 取消 </a-button>
        <a-button type="primary" @click="submitCourse" :disabled="tipsRelated" :loading="tipsRelated"> 提交 </a-button>
      </div>
    </a-modal> -->
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import Popover from '@/components/WKPopover/popover';
import { queryTeacherCalendar, timezones } from '@/api/headTeacher';

import { TEACHERINFO } from '@/store/mutation-types';
// const LOAD_NUM = 20;
const session = storage.createStore(sessionStorage);
const colors = {
  red: {
    primary: '#e74c3c',
    secondary: '#fff',
  },
  blue: {
    primary: '#009CFF',
    secondary: '#fff',
  },
  yellow: {
    primary: '#f39c12',
    secondary: '#fff',
  },
  green: {
    primary: '#00CF64',
    secondary: '#fff',
  },
  grey: {
    primary: 'red',
    secondary: '#fff',
  },
  orange: {
    primary: '#FF9A2E',
    secondary: '#fff',
  },
  lightBlue: {
    primary: '#009cff',
    secondary: '#fff',
  },
  lightGreen: {
    primary: '#00cf64',
    secondary: '#fff',
  },
};
export default {
  components: {
    FullCalendar,
    Popover,
  },
  data() {
    return {
      timezoneList: [],

      teacherName: this.$route.query.teacherName,
      timezone: this.$route.query.timezone,
      teacherId: this.$route.query.teacherId,
      feedbackTime: null,
      tipsVisible: false,
      popoverids: '',
      eventsArr: null,
      FullCalendarBol: false,
      calendarOptions: {
        weekNumberCalculation: 'ISO',
        locale: 'zh-cn',
        buttonText: {
          today: '本周',
          month: '月',
          week: '周',
          day: '日',
        },
        displayEventEnd: true, // 所有视图显示结束时间
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false, // 设置时间为24小时
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        height: 'calc( 100vh - 170px)',

        initialView: 'timeGridWeek',
        events: [],
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEventRows: true,
        views: {
          dayGridMonth: {
            dayMaxEventRows: 4,
          },
        },
        //    eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        allDaySlot: false,
        datesSet: this.datesSetfuc,
      },
      viewType: '', // 日历类型
      changecourse: true,
      times: [],
      courseSchedule: null, // 课程详情
      courseNameId: null, // 修改课程变量id
      selectArr: [], // 课程调级下拉
      courseBtn: false,
      showLoading: false,
      showAllTime: false,
      newDateParamsTime: null,
      availableTime: null,
      currentView: null, // 组件名称
      studentInfo: [], // 学生信息
      studentLiveUrl: '',
      workStatus: session.get('WorkerStatus'),
      newclickInfo: null,
      dataList: null,
      num: 1,
      paddingBol: false,
      resignVisible: false,
      tipsRelated: false,
      visibleType: null,
    };
  },
  computed: {
    expandBol() {
      return this.$store.state.user.expand;
    },
  },
  watch: {
    expandBol() {
      this.calendarOptions.initialView = this.viewType;
      this.initCalendar(this.newDateParamsTime);
    },
  },
  methods: {
    moment,
    changeTimezone(val) {
      console.log(val);
      this.timezone = val;
      this.newDateParamsTime.timezone = val;
      console.log(this.newDateParamsTime);
      this.showLoading = true;

      this.initCalendar(this.newDateParamsTime);
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    changeCourse() {
      this.$refs.FullCalendar.getApi().addEventSource([]);
    },

    onFeedBack(data) {
      this.visibleType = data;
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    onSeachsectionName(value) {
      this.selectArr = [];
      this.getcourseId(
        this.courseSchedule.courseSectionRespDTO.course.uuid,
        this.newclickInfo.event.id,
        this.courseSchedule.courseSectionRespDTO.data.subjectType,
        this.courseSchedule.courseSectionRespDTO.data.level1,
        this.courseSchedule.courseSectionRespDTO.data.level2,
        1,
        value,
      );
    },
    // onFeedBack(data) {
    //   this.currentView = data;
    // },
    toCourseDetails(courseDateUuid) {
      this.$router.push({ path: 'courseDetails', query: { uuid: courseDateUuid } });
    },
    toCLassIn() {
      const parame = {
        uid: this.courseSchedule.teacher.classInId,
        courseId: this.courseSchedule.formClassInId,
        classId: this.courseSchedule.classInId,
        deviceType: 1,
        lifeTime: 86400,
      };
      this.$post('/api/service_center/login_link', parame).then((res) => {
        let urlStr = res.data.content.data;
        urlStr = `https://www.eeo.cn/client/invoke/index.html${urlStr.substring(urlStr.indexOf('?'))}`;
        const $iframe = document.createElement('a');
        $iframe.style.display = 'none';
        $iframe.setAttribute('href', urlStr);
        $iframe.setAttribute('target', '_blank');
        document.body.appendChild($iframe);
        $iframe.click();
        setTimeout(() => {
          document.body.removeChild($iframe);
        }, 1000);
      });
    },
    toStudentInfo() {
      if (this.courseSchedule.courseSectionRespDTO.course.courseType === 'PUBLIC') return;
      this.studentInfo = this.courseSchedule.students;
      this.studentLiveUrl =
        this.courseSchedule.courseSectionRespDTO.course.courseType === 'TRIAL' ? this.courseSchedule.liveUrl : '';
      // this.currentView = 'Popover';
      session.set('studentInfo', this.studentInfo);
      this.$router.push({ path: '/student' });
    },
    initCalendar(dateParams) {
      queryTeacherCalendar(dateParams).then((res) => {
        const resData = res.data;
        const eventsArr = [];
        Object.keys(resData).forEach((value) => {
          // resData[value] = resData[value].filter((item) => {
          //   if (!item.crossDay && item.status === 1) {
          //     return false;
          //   }
          //   return true;
          // });
          eventsArr.push(...this.initDate(resData[value]));
        });
        //  this.cleanEvent('FullCalendar');
        //   this.showLoading = false;
        //   this.$refs.FullCalendar.getApi().addEventSource(eventsArr);
        const hotArray = [];
        if (this.viewType === 'timeGridWeek') {
          this.cleanEvent('FullCalendar');
          this.showLoading = false;
          this.$refs.FullCalendar.getApi().addEventSource(eventsArr);
          const course = document.getElementsByClassName('course');

          course.forEach((k) => {
            // eslint-disable-next-line no-param-reassign
            k.parentNode.style['z-index'] = '8';
          });

          // this.$post(`/api/teacher/hot_times/search`, dateParams).then((i) => {
          //   Object.keys(i.data).forEach((value) => {
          //     hotArray.push(...this.initHotTimesDate(i.data[value]));
          //   });
          //   const newHot = this.filterArray(eventsArr, hotArray);
          //   eventsArr = eventsArr.concat(newHot);
          //   this.cleanEvent('FullCalendar');
          //   this.showLoading = false;
          //   this.$refs.FullCalendar.getApi().addEventSource(eventsArr);
          //   const hot = document.getElementsByClassName('hotcls');
          //   const course = document.getElementsByClassName('course');
          //   hot.forEach((k) => {
          //     // eslint-disable-next-line no-param-reassign
          //     k.parentNode.style['z-index'] = '9';
          //   });
          //   course.forEach((k) => {
          //     // eslint-disable-next-line no-param-reassign
          //     k.parentNode.style['z-index'] = '8';
          //   });
          // });
        } else {
          this.cleanEvent('FullCalendar');
          this.showLoading = false;
          this.$refs.FullCalendar.getApi().addEventSource(eventsArr);
        }
      });
    },
    filterArray(eventsArr, hotArray) {
      const filterArr = [];
      hotArray.forEach((i) => {
        let iEnd = i.end;

        if (i.start.includes('23:00:00')) {
          const e = moment(iEnd).add(1, 'days').format('YYYY-MM-DD');
          iEnd = `${e} 00:00:00`;
        }

        const hotStart = moment(i.start).format('X');
        const hotEnd = moment(iEnd).format('X');

        eventsArr.some((k) => {
          const startTime = moment(k.start).format('X');
          const endTime = moment(k.end).format('X');
          if (k.status.includes('已排课') && i.start.substring(0, 10) === k.start.substring(0, 10)) {
            if (hotStart <= endTime && hotEnd >= startTime) {
              filterArr.push(i);
              return true;
            }
          }
          return false;
        });
      });
      filterArr.forEach((i) => {
        hotArray.forEach((m, index) => {
          if (m.start === i.start && m.end === i.end) {
            hotArray.splice(index, 1);
          }
        });
      });
      return hotArray;
    },
    // 工作时间
    initAvailableTime(dateParams) {
      this.$post('/api/service_center/available_time/currentweek', dateParams)
        .then((res) => {
          if (res.status === 200) {
            this.availableTime = res.data;
            if (this.workStatus.workStatus === 'WORKING_AVAILABLE') {
              if (
                (this.workStatus.workType === 'F' && Number(this.availableTime.availableTime) < 50) ||
                (this.workStatus.workType === 'P' && Number(this.availableTime.availableTime) < 20)
              ) {
                this.showAllTime = true;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 清空事件
    cleanEvent(name) {
      this.showLoading = true;
      this.$refs[name]
        ?.getApi?.()
        ?.getEvents?.()
        // eslint-disable-next-line array-callback-return
        ?.map?.((_) => {
          _.remove();
        });
    },
    initHotTimesDate(eventList) {
      const list = [];
      if (eventList.length <= 0) return list;
      eventList.forEach((item) => {
        const start = `${item.startDay} ${item.startTime}`;
        const end = `${item.startDay} ${item.endTime}`;
        list.push({
          id: 1,
          start,
          backgroundColor: '#fff0',
          end,
          className: 'hotcls',
        });
      });
      return list;
    },
    initDate(eventList) {
      console.log(eventList);
      const list = [];
      if (eventList.length <= 0) return list;
      eventList.forEach((item) => {
        let statusColor = '';
        let status = '';
        let className = '';
        let courseType = '';
        switch (item.availabilityType) {
          case 'AVAILABLE':
            status = '可用';
            statusColor = colors.green;
            className = 'lightGreen';
            switch (item.timeType) {
              case 'TRIAL':
                status = '体验课可用';
                statusColor = colors.orange;
                className = 'orange';
                break;
              case 'STANDARD':
                status = '标准课可用';
                statusColor = colors.lightGreen;
                className = 'lightGreen';
                break;
              case 'ALL':
                status = '可用';
                statusColor = colors.lightBlue;
                className = 'lightBlue';
                break;
              default:
                break;
            }
            break;
          case 'UNAVAILABLE':
            status = '不可用';
            statusColor = colors.red;
            className = 'lightRed';
            break;
          case 'COURSE_SCHEDULE':
            status = '已排课';
            statusColor = colors.blue;
            switch (item.courseType) {
              case 'STANDARD':
                courseType = '标准课';
                break;
              case 'TRIAL':
                courseType = '体验课';
                break;
              case 'PUBLIC':
                courseType = '公开课';
                break;
            }
            break;
          default:
            break;
        }
        const start = `${item.startDate} ${item.startTime}`;
        const end = `${item.endDate} ${item.endTime}`;
        let temp = ``;
        if (courseType) {
          temp = `(${courseType})`;
        }
        list.push({
          id: item.courseScheduleId,
          start,
          title: `${
            item.crossDay
              ? `${moment(start).format('DD')}日${moment(start).format('HH:mm')}-${moment(end).format('DD')}日${moment(
                  end,
                ).format('HH:mm')}`
              : `${moment(start).format('HH:mm')}-${moment(end).format('HH:mm')} ${status}${temp}`
          }`,
          textColor: statusColor.secondary,
          // backgroundColor: statusColor.primary,
          end,
          color: statusColor.primary,
          className:
            // eslint-disable-next-line no-nested-ternary
            this.viewType !== 'dayGridMonth' && item.customData !== null && !item.customData.isAdjustSection
              ? `dotcls course ${className}`
              : item.availabilityType === 'COURSE_SCHEDULE'
              ? `course ${className}`
              : `${className}`,
          isAdjustSection: item.customData !== null ? item.customData.isAdjustSection : null,
          status,
        });
      });
      console.log(list);
      return list;
    },
    // 点击事件
    handleEventClick(clickInfo) {
      console.log(clickInfo, clickInfo.event.id);
      this.selectArr = [];
      this.num = 1;
      this.dataList = null;
      this.newclickInfo = clickInfo;
      if (this.viewType === 'dayGridMonth') return;
      this.showLoading = true;
      if (clickInfo.event.id === 'null' || clickInfo.event.id === '1') {
        this.showLoading = false;
        this.$refs.FullCalendar.getApi().addEventSource([]);
        return;
      }
      this.$post('/api/service_center/course_schedule', {
        courseScheduleId: clickInfo.event.id,
        timezone: this.timezone,
      }).then((res) => {
        this.courseSchedule = res.data.content;
        this.courseSchedule.timeValue = `${this.moment(this.courseSchedule.startDateTime).format(
          'YYYY年MM月DD日',
        )} ${this.moment(this.courseSchedule.startDateTime).format('dddd')}${this.courseSchedule.startDateTime.substr(
          11,
        )}-${this.courseSchedule.endDateTime.substr(11)}`;
        this.popoverids = clickInfo.event.id;
        this.courseName = this.courseSchedule.scheduleName;
        // 更新课程进度
        if (clickInfo.event.extendedProps.isAdjustSection !== null) {
          this.getcourseId(
            this.courseSchedule.courseSectionRespDTO.course.uuid,
            clickInfo.event.id,
            this.courseSchedule.courseSectionRespDTO.data.subjectType,
            this.courseSchedule.courseSectionRespDTO.data.level1,
            this.courseSchedule.courseSectionRespDTO.data.level2,
            1,
          );
          return;
        }
        // 更新日历中的事件
        this.showLoading = false;
        this.$refs.FullCalendar.getApi().addEventSource([]);
      });
    },
    debounce(fn, wait) {
      let timer = null;
      return function () {
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(fn, wait);
      };
    },
    // 滚动时触发（防止抖动）
    handlePopupScroll() {
      this.loadMoreData();
    },
    // 加载更多数据到select框
    loadMoreData() {
      const renderedLen = this.selectArr.length; // 已渲染的下拉列表长度
      const totalLen = this.dataList.totalCount; // 当前数据源的长度
      if (this.num > this.dataList.totalPages) return;
      if (renderedLen < totalLen) {
        if (this.paddingBol) {
          this.num += 1;
          this.getcourseId(
            this.courseSchedule.courseSectionRespDTO.course.uuid,
            this.newclickInfo.event.id,
            this.courseSchedule.courseSectionRespDTO.data.subjectType,
            this.courseSchedule.courseSectionRespDTO.data.level1,
            this.courseSchedule.courseSectionRespDTO.data.level2,
            this.num,
          );
        }
      }
    },
    // 获取课程id
    getcourseId(courseId, id, subjectType, level1, level2, num, sectionName) {
      this.paddingBol = false;
      this.$post('/api/service_center/course/section/search', {
        courseId,
        courseStructure: { level1, subjectType, level2 },
        // pageNum: num,
        sectionName,
      })
        .then((res) => {
          this.dataList = res.data.content;
          this.popoverids = id;
          this.selectArr = this.selectArr.concat(res.data.content);
          this.showLoading = false;
          this.$refs.FullCalendar.getApi().addEventSource([]);
        })
        .finally(() => {
          this.paddingBol = true;
          this.showLoading = false;
        });
    },
    // 返回修改课程按钮
    changecourseFuc() {
      this.changecourse = false;
      this.$refs.FullCalendar.getApi().addEventSource([]);
    },
    handleSelectChange(value) {
      this.courseNameId = value;
    },
    // 更改课程提交数据
    // sumCourse() {
    submitTips() {
      this.resignVisible = true;
    },

    onCanceltips() {
      this.resignVisible = false;
    },
    getvisibleChange1() {
      this.courseSchedule = null;
      this.popoverids = '';
      this.changecourse = true;
      this.$refs.FullCalendar.getApi().addEventSource([]);
    },
    getPopupContainer(trigger) {
      return trigger.parentElement.parentElement.parentElement.parentElement.parentElement;
    },
    getPopupContainer1(trigger) {
      return trigger.parentElement.parentElement.parentElement;
    },
    datesSetfuc(dateInfo) {
      this.viewType = dateInfo.view.type;
      switch (this.viewType) {
        case 'dayGridMonth':
          this.calendarOptions.buttonText.today = '本月';
          break;
        case 'timeGridWeek':
          this.calendarOptions.buttonText.today = '本周';
          break;
        case 'timeGridDay':
          this.calendarOptions.buttonText.today = '今天';
          break;
        default:
          break;
      }
      this.times = [dateInfo.startStr.substr(0, 10), dateInfo.endStr.substr(0, 10)];
      this.showLoading = true;
      // 切换周修改日期参数 查询课程日历是 初始时间-1 结束时间+1/ 查询可用时间正常传入一周时间段
      const changeTimeEnd = new Date(dateInfo.endStr).getTime() - 24 * 60 * 60 * 1000;
      const changeTimeStart = new Date(dateInfo.startStr).getTime() - 24 * 60 * 60 * 1000;
      const dateParams = {
        endDate: moment(new Date(changeTimeEnd)).format('YYYY-MM-DD'),
        startDate: dateInfo.startStr.substr(0, 10),
      };
      const newDateParams = {
        teacherId: this.teacherId,
        timezone: this.timezone,
        endDate: dateInfo.endStr.substr(0, 10),
        startDate: moment(new Date(changeTimeStart)).format('YYYY-MM-DD'),
      };
      this.newDateParamsTime = newDateParams;
      this.showAllTime = false;
      this.initCalendar(newDateParams);
      // this.initAvailableTime(dateParams);
    },
    cancal(data) {
      if (data) {
        this.tipsVisible = true;
        // this.initTAskTotal(this.accountId);
        this.countDown();
      }
      this.visibleType = null;
      this.currentView = null;
    },
    countDown() {
      let secondsToGo = 5;
      this.feedbackTime = setInterval(() => {
        secondsToGo -= 1;
      }, 1000);
      setTimeout(() => {
        clearInterval(this.feedbackTime);
        this.feedbackTime = null;
        this.tipsVisible = false;
      }, secondsToGo * 1000);
    },
    onCancelTips() {
      clearInterval(this.feedbackTime);
      this.feedbackTime = null;
      this.tipsVisible = false;
    },
  },
  created() {
    timezones().then((i) => {
      this.timezoneList = i.data.content;
    });
  },
};
</script>
<style lang="less">
.demo-app-calendar {
  height: 2000px;
}
.orange {
  background-color: #fff7e8 !important;
  color: #ff9a2e !important;
  span {
    color: #ff9a2e !important;
  }
}
.lightGrey {
  background-color: rgba(201, 205, 212, 0.1) !important;
  color: #c9cdd4 !important;
  span {
    color: #c9cdd4 !important;
  }
}
.lightRed {
  background-color: rgb(250 125 112) !important;
  color: #fff !important;
  span {
    color: #fff !important;
  }
}
.lightBlue {
  background-color: #e8f6ff !important;
  color: #009cff !important;
  span {
    color: #009cff !important;
  }
}
.lightGreen {
  background-color: rgba(35, 195, 67, 0.04) !important;
  color: #00cf64 !important;
  span {
    color: #00cf64 !important;
  }
}
// .hotcls
.fc-timegrid-event-harness-inset {
  // inset: 264px 0% -304px 0% !important;
  // z-index: 1 !important;
  left: 0 !important;
}
p {
  margin-bottom: 1em;
}
.demo-app {
  display: flex;
  height: calc(100vh - 92px);
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  .leave {
    margin: 0 0 0 20px;
  }
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}
.tooltips {
  max-width: 100%;
  .ant-tooltip-inner {
    background-color: #fff;
    color: #333;
    padding: 20px;
  }
  .ant-tooltip-arrow::before {
    background-color: #fff;
  }
}
.courseTime {
  .ant-popover-inner {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
  .ant-popover-inner-content {
    background-color: rgba(255, 238, 238, 0.8);
    border-radius: 8px;
    color: #ff5353;
    padding: 20px;
  }
  .ant-popover-arrow {
    border-top-color: rgba(255, 238, 238, 0.5) !important;
    border-left-color: rgba(255, 238, 238, 0.5) !important;
  }
}
.allTime {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}
.demo-app-main {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 16px;
  overflow-y: auto;
  background-color: #fff;
  .statistics-time {
    width: 99%;
    display: flex;
    justify-content: space-between;
    color: #999;
    position: absolute;
    top: 50px;
    .leave-related {
      width: 30%;
      display: flex;
      justify-self: start;
    }
  }
  .schedule-color {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      margin: 0 5px 0 20px;
    }
    i {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 4px;
      margin: 0 5px 0 20px;
    }
    span {
      background-color: transparent !important;
    }
    .schedule-blue {
      background-color: #009cff;
      color: #009cff;
    }
    .schedule-green {
      background-color: #00cf64;
      color: #00cf64;
    }
    .schedule-gray {
      background-color: #c9cdd4;
      color: #333;
    }
  }
  .fc-timegrid-body {
    width: 100% !important;
  }
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #009cff;
    border-color: #009cff;
  }
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
  .fc .fc-button-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 156, 255, 0.5);
  }
  .fc .fc-button-primary:focus {
    background-color: #009cff;
    border-color: #009cff;
    color: #fff;
  }
  .fc .fc-button-primary {
    color: #333;
    background-color: #f7f7f7;
    border-color: #f7f7f7;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    .fc-event-main {
      padding: 0;
    }
  }
  .fc .fc-toolbar {
    display: block;
    .fc-toolbar-chunk {
      float: left;
      margin-right: 20px;
    }
    .fc-toolbar-chunk:nth-last-child(1) {
      float: right;
    }
    .fc-toolbar-title {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .coursePopover .ant-popover-arrow {
    display: none;
  }
  .fc-scrollgrid-sync-inner a {
    color: #333;
  }
  .fc-day-today .fc-scrollgrid-sync-inner a {
    color: #009cff;
  }
  .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
    color: #009cff;
    &::after {
      content: '(今天)';
      display: inline-block;
      color: #009cff;
    }
  }
}
.calendarTools .fc-toolbar,
.demo-app-main .fc .fc-toolbar {
  height: 80px;
}

.fc {
  /* the calendar root */
  // max-width: 1100px;
  margin: 0 auto;
  .fc-timegrid-slot {
    height: 2.5em;
  }
}
.closed {
  float: right;
  font-size: 20px;
}
.hotcls {
  width: 98%;
  margin: auto;
  border: none !important;
}
.dotcls::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: red;
  top: 0;
  right: 0;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none;
  border: 1px solid #fff;
}
.eventCls {
  height: 100%;
  border-radius: 4px;
  // border-color: #005184 !important;
  box-shadow: 0px 0px 0px 2px var(--fc-page-bg-color, #005184);
}
.calendarLoading {
  width: 100%;
  height: 100vh;
  text-align: center;
  line-height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1040;
  background-color: rgba(255, 255, 255, 0.4);
}
.course-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
  &:hover {
    color: #009cff;
    cursor: pointer;
    text-decoration: underline;
  }
  span {
    display: inline-block;
    width: 40px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    color: #666;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
  }
}
.course-info {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  & > span {
    display: inline-block;
    width: 70px;
    color: #999;
    margin-right: 20px;
    text-align: right;
  }
}
.students-info {
  cursor: pointer;
}
.course-form {
  label {
    margin-right: 20px;
  }
  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #ff5353;
  }
  .ant-btn {
    width: 160px;
    height: 40px;
    border-radius: 10px;
  }
  .ant-btn-primary {
    background: #009cff;
    margin-left: 20px;
  }
}
.feedBacktips {
  z-index: 1050;
}
.coursePopover {
  .ant-popover-content {
    width: 440px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
    .ant-popover-inner {
      box-shadow: none;
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
  img {
    float: right;
    margin-top: -50px;
  }
  .operation {
    display: block;
    width: 50px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    background-color: #009cff;
    float: right;
  }
}
.submit-tips {
  .ant-modal-content {
    padding: 20px 20px 0;
    border-radius: 30px;
    .btn {
      display: flex;
      justify-content: space-between;
      .ant-btn {
        width: 200px;
        height: 46px;
        margin: 20px 0 40px;
        border-radius: 10px;
        font-size: 16px;
      }
      .ant-btn-primary {
        background: #009cff;
      }
    }
  }
  .tips-main {
    text-align: center;
    .tips-title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      margin-bottom: 20px;
    }
    .tips-text {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: left;
      span {
        font-weight: 600;
      }
    }
    .tips-err {
      font-size: 18px;
      font-weight: 400;
      color: #ff5353;
      line-height: 25px;
    }
  }
}
.calendar-header {
  margin-bottom: 16px;
  position: absolute;
  top: 68px;
  left: 20px;
  width: 100%;
}
.tag-wrap {
  position: absolute;
  right: 50px;
  top: 0;
}
.tag-item {
  width: 12px;
  height: 14px;
  margin-top: 2px;
}
.tag-pd {
  padding-left: 10px;
  display: inline-flex;
}
</style>
